<template>
    <section>
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   class="loaderPreview"
                   rounded="sm">
            <div class="post">
                <div v-if="error" class="error">
                    {{ error }}
                </div>
            </div>

            <b-row v-if="(meta.breadcrumb || meta.pageTitle) && dataLoaded"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="6">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ meta.pageTitle }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="6"
                       cols="12">
                    <b-button data-id="0"
                              :to="'/addeditemail/0'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="button"
                              variant="outline-success">
                        <feather-icon icon="PlusIcon" size="18" />
                        Add New Email
                    </b-button>
                </b-col>
            </b-row>

            <b-row class="match-height" v-if="bulkEmails && dataLoaded">
                <b-col lg="12">
                    <b-card>
                        <div class="pb-2">
                            <div class="row d-flex justify-content-between flex-wrap">
                                <b-col lg="8"></b-col>
                                <b-col lg="4">
                                    <b-form-group label="Search" v-if="totalRows>0"
                                                  label-cols-sm="3"
                                                  label-align-sm="right"
                                                  label-size="sm"
                                                  label-for="filterInput"
                                                  class="mb-0 align-items-center">
                                        <b-input-group size="sm">
                                            <b-form-input id="filterInput"
                                                          v-model="filter"
                                                          type="text"
                                                          placeholder="Type to Search" />
                                            <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">
                                                    Clear
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </div>
                        </div>
                        <b-table striped
                                 bordered
                                 hover
                                 responsive
                                 fixed
                                 class="vgt-table bordered"
                                 :per-page="perPage"
                                 :current-page="currentPage"
                                 :items="bulkEmails"
                                 :fields="bulkEmailsTablefields"
                                 :sort-by.sync="sortBy"
                                 :sort-desc.sync="sortDesc"
                                 :sort-direction="sortDirection"
                                 :filter="filter"
                                 :filter-included-fields="filterOn"
                                 @filtered="onFiltered"
                                 show-empty>
                            <template #empty="scope">
                                Record doesn't exist
                            </template>
                            <template v-slot:cell(IsScheduled)="data">
                                {{data.item.IsScheduled == true ? " Scheduled" : " Draft"}}
                            </template>
                            <template v-slot:cell(EmailOpenCount)="data">
                                {{data.item.EmailDeliveryCount > 0 ? (data.item.EmailOpenCount / data.item.EmailDeliveryCount) * 100 : 0}}
                            </template>
                            <template v-slot:cell(EmailClickCount)="data">
                                {{data.item.EmailDeliveryCount > 0 ? (data.item.EmailClickCount / data.item.EmailDeliveryCount) * 100 : 0 }}
                            </template>
                            <!--<template v-slot:cell(ScheduleDate)="data">
                                {{ data.item.ScheduleDate | moment("DD-MMMM-YYYY HH:mm") }}
                            </template>-->

                            <template v-slot:cell(Id)="data">
                                <b-button v-if="data.item.IsScheduled"
                                          title="View"
                                          :to="'/addeditemail/' + data.item.Id"
                                          :data-id="data.item.Id"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          v-b-tooltip.hover.v-primary
                                          variant="flat-primary"
                                          class="btn-icon">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>

                                <b-button v-if="!data.item.IsScheduled"
                                          title="Edit"
                                          :to="'/addeditemail/' + data.item.Id"
                                          :data-id="data.item.Id"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          v-b-tooltip.hover.v-info
                                          variant="flat-info"
                                          class="btn-icon">
                                    <feather-icon icon="EditIcon" />
                                </b-button>

                                <b-button :data-id="data.item.Id"
                                          @click="cloneBulkEmail"
                                          title="Clone"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          v-b-tooltip.hover.v-success
                                          variant="flat-success"
                                          class="btn-icon">
                                    <feather-icon icon="CopyIcon" />
                                </b-button>

                                <b-button v-if="!data.item.IsScheduled"
                                          title="Delete"
                                          :data-id="data.item.Id"
                                          @click="deleteBulkEmail"
                                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          v-b-tooltip.hover.v-danger
                                          variant="flat-danger"
                                          class="btn-icon">
                                    <feather-icon icon="ArchiveIcon" />
                                </b-button>
                            </template>
                        </b-table>

                        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                            <!-- page length -->
                            <b-form-group label="Show"
                                          label-cols="4"
                                          label-align="center"
                                          label-size="sm"
                                          label-for="sortBySelect"
                                          class="text-nowrap mb-md-0 mr-1">
                                <b-form-select id="perPageSelect"
                                               v-model="perPage"
                                               size="sm"
                                               inline
                                               :options="pageOptions" />
                            </b-form-group>

                            <!-- pagination -->
                            <div v-if="totalRows > perPage">
                                <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                                    <!-- page length -->
                                    <b-form-group label="Show"
                                                  label-cols="4"
                                                  label-align="center"
                                                  label-size="sm"
                                                  label-for="sortBySelect"
                                                  class="text-nowrap mb-md-0 mr-1">
                                        <b-form-select id="perPageSelect"
                                                       v-model="perPage"
                                                       size="sm"
                                                       inline
                                                       :options="pageOptions" />
                                    </b-form-group>

                                    <!-- pagination -->
                                    <div>
                                        <b-pagination v-model="currentPage"
                                                      :total-rows="totalRows"
                                                      :per-page="perPage"
                                                      first-number
                                                      last-number
                                                      prev-class="prev-item"
                                                      next-class="next-item"
                                                      class="mb-0">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </b-card-body>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-overlay>
    </section>
</template>

<script>
    import BCardCode from "@core/components/b-card-code";

    import {
        BCard,
        BCardHeader,
        BCardBody,
        BCardImg,
        BRow,
        BCol,
        BCardText,
        BLink,
        BButton,
        BModal,
        VBModal,
        BFormInput,
        BForm,
        BFormRow,
        BFormValidFeedback,
        BFormInvalidFeedback,
        BFormGroup,
        BTabs,
        BTab,
        BTable,
        BFormSelect,
        BFormCheckbox,
        BFormRadio,
        BFormFile,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormTextarea,
        BOverlay,
        BPagination,
        BAlert,
        BBreadcrumb, BBreadcrumbItem,
        VBTooltip,
    } from "bootstrap-vue";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        digits,
        alphaDash,
        length,
    } from "@validations";
    import { $themeConfig } from "@themeConfig";
    import { VueGoodTable } from "vue-good-table";
    import Ripple from "vue-ripple-directive";
    import jwt from "@/auth/jwt/useJwt";

    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });

    export default {
        metaInfo: {
            title: 'Landingly | Broadcasts'
        },
        components: {
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BTabs,
            BTab,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormTextarea,
            VueGoodTable,
            BTable,
            BFormSelect,
            BFormCheckbox,
            BFormRadio,
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BPagination,
            BAlert,
            BFormFile,
            BBreadcrumb, BBreadcrumbItem,
        },
        data() {
            return {
                loading: true,
                variant: "light",
                opacity: 0.85,
                blur: "2px",

                meta: {
                    pageTitle: 'Broadcasts',
                    breadcrumb: [
                        {
                            text: 'Broadcasts',
                            active: true
                        },
                    ],
                },
                dataLoaded: false,
                bulkEmails: null,

                error: null,
                WebAPIURL: $themeConfig.app.apiURL,

                bulkEmailsTablefields: [
                    //{
                    //    key: 'FromName',
                    //    label: 'From Name',
                    //    sortable: true,
                    //},
                    {
                        key: "Subject",
                        label: "Subject",
                        sortable: true,
                    },
                    //{
                    //    key: 'ReplyToEmail',
                    //    label: 'Reply To Email',
                    //    sortable: true
                    //},
                    {
                        key: "IsScheduled",
                        label: "Status",
                        sortable: true,
                        thStyle: { width: "200px" },
                    },
                    //{
                    //    key: "ScheduleDate",
                    //    label: "Scheduled On",
                    //    sortable: true,
                    //},
                    //{
                    //    key: "TimeZoneValue",
                    //    label: "Time Zone",
                    //    sortable: true,
                    //},
                    {
                        key: "EmailDeliveryCount",
                        label: "Sent",
                        sortable: true,
                        thStyle: { width: "100px" },
                    },
                    {
                        key: "EmailOpenCount",
                        label: "Open",
                        sortable: true,
                        thStyle: { width: "100px" },
                    },
                    {
                        key: "EmailClickCount",
                        label: "Click",
                        sortable: true,
                        thStyle: { width: "100px" },
                    },

                    //{
                    //    key: 'TimeZoneOffSet',
                    //    label: 'Time Zone Offset',
                    //    sortable: true
                    //},

                    {
                        key: "Id",
                        label: "Actions",
                        sortable: false,
                        thStyle: { width: "175px" },
                    },
                ],
                emailPageLength: 10,
                emailSearchTerm: "",
                perPage: 20,
                pageOptions: [10, 20, 50, 100],
                totalRows: 1,
                currentPage: 1,
                sortBy: "",
                sortDesc: false,
                sortDirection: "asc",
                filter: null,
                filterOn: [],
            };
        },
        directives: {
            "b-modal": VBModal,
            'b-tooltip': VBTooltip,
            Ripple,
        },
        created() {
            this.fetchBulkEmails();
        },
        beforeCreate() {
            
        },
        destroyed() {
            //document.querySelector('a[href*="/sendemails"]').parentNode.classList.remove("active");
        },
        watch: {
            $route: "fetchBulkEmails",
        },

        methods: {
            onFiltered(filteredItems) {
                //this.totalRows = filteredItems.length;
                //this.currentPage = 1;
            },

            fetchBulkEmails: async function () {
                this.error = this.bulkEmails = null;
                var self = this;
                this.loading = true;
                var axios = require("axios");
                const access_token = await jwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };

                axios
                    .get("" + this.WebAPIURL + "/api/emails/BulkEmails", { headers })
                    .then(function (response) {
                        self.bulkEmails = response.data;
                        self.totalRows = response.data.length;
                        self.loading = false;
                        self.dataLoaded = true;
                    })
                    .catch(function (error) {
                        self.error = error;
                        self.loading = false;
                        self.dataLoaded = true;
                        console.log("error:: " + error);
                    });
            },

            cloneBulkEmail: async function (e) {
                const emailId = e.currentTarget.getAttribute("data-id");

                this.error = null;
                var self = this;

                var axios = require("axios");
                const access_token = await jwt.getToken();
                var qs = require("qs");
                this.$swal({
                    title: "Clone Email",
                    text: "Are you sure?",
                    showCancelButton: true,
                    confirmButtonText: "Clone",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true;
                        var data = qs.stringify({
                            Id: emailId,
                        });

                        var config = {
                            method: "post",
                            url:
                                "" + self.WebAPIURL + "/api/emails/CreateBulkEmailClone?" + data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };
                        axios(config)
                            .then(function (response) {
                                self.loading = false;
                                self.fetchBulkEmails();
                            })
                            .catch(function (error) {
                                console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },

            deleteBulkEmail: async function (e) {
                const projId = e.currentTarget.getAttribute("data-id");

                this.error = null;
                var self = this;
                var axios = require("axios");
                const access_token = await jwt.getToken();
                var qs = require("qs");
                this.$swal({
                    title: "Delete Email",
                    text: "Are you sure?",
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true;
                        var data = qs.stringify({
                            Id: projId,
                        });

                        var config = {
                            method: "post",
                            url: "" + self.WebAPIURL + "/api/emails/RemoveBulkEmail?" + data,
                            headers: {
                                Authorization: "bearer " + access_token,
                            },
                            data: data,
                        };
                        axios(config)
                            .then(function (response) {
                                self.loading = false;
                                self.fetchBulkEmails();
                            })
                            .catch(function (error) {
                                console.log(error);
                                self.loading = false;
                            });
                    }
                });
            },
        },
    };
</script>   